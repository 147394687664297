import http from "./http-client";

class AdminService {
  createReceipt(receipt) {
    return http.post("/Receipt/AddEditReceipt", receipt);
  }

  fetchReceipts(
    page,
    perPage,
    sortBy,
    sortDesc,
    date,
    categoryId,
    eventId,
    amount,
    last4cc,
    cardId,
    addedBy
  ) {
    return http.get("/Receipt/GetReceipt", {
      params: {
        Page: page || null,
        PageSize: perPage || null,
        SortBy: sortBy || null,
        IsDesc: sortDesc === null ? null : sortDesc,
        ReceiptDate: date || null,
        CategoryID: categoryId || null,
        EventID: eventId || null,
        Amount: amount || null,
        Last4DigitofCC: last4cc || null,
        CardTypeID: cardId || null,
        AddedBy: addedBy || null,
      },
    });
  }

  fetchReceiptById(id) {
    return http.get(`/Receipt/GetReceipt/${id}`);
  }
  exportExcel(
    type,
    date,
    categoryId,
    eventId,
    amount,
    last4cc,
    cardId,
    addedBy,
    sortBy,
    sortDesc,
    perPage
  ) {
    const obj = {
      exportType: type,
      receiptFilter: {
        page: 1,
        pageSize: perPage,
      },
    };
    if (date !== "") {
      obj.receiptFilter.receiptDate = date;
    }
    if (categoryId !== "") {
      obj.receiptFilter.categoryID = categoryId;
    }
    if (eventId !== "") {
      obj.receiptFilter.eventID = eventId;
    }
    if (amount !== "") {
      obj.receiptFilter.amount = Number(amount);
    }
    if (last4cc !== "") {
      obj.receiptFilter.last4DigitofCC = parseInt(last4cc);
    }
    if (cardId !== "") {
      console.log(cardId);
      obj.receiptFilter.cardTypeID = cardId;
    }
    if (addedBy !== "") {
      obj.receiptFilter.addedBy = addedBy;
    }
    if (sortBy.length !== 0) {
      console.log(sortBy);
      let SortBy = null;
      switch (sortBy[0]) {
        case "categoryName":
          SortBy = "Category";
          break;
        case "eventName":
          SortBy = "Event";
          break;
        case "amount":
          SortBy = "Amount";
          break;
        case "last4DigitofCC":
          SortBy = "Last4DigitofCC";
          break;
        case "receiptDate":
          SortBy = "ReceiptDate";
          break;
        case "addedBy":
          SortBy = "AddedBy";
          break;
        case "cardTypeName":
          SortBy = "CardTypeName";
          break;
      }
      obj.receiptFilter.sortBy = SortBy;
    }
    if (sortDesc.length !== 0) {
      obj.receiptFilter.isDesc = sortDesc[0];
    }
    return http.post("/Receipt/export-receipt", obj, {
      responseType: "blob",
    });
  }
  deleteReceipts(ids) {
    return http.post("/Receipt/DeleteReceipt", ids);
  }
}

export default new AdminService();
