<template>
  <div class="admin-container">
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="d-flex align-center justify-space-between">
          <span class="display-1 text-sm-h3 font-weight-bold">Receipts</span>
          <v-tooltip bottom class="mr-2" v-if="selectedReceipts.length !== 0">
            <template #activator="{ on, attrs }">
              <v-btn
                class="mr-2"
                type="button"
                color="primary"
                dark
                large
                v-bind="attrs"
                v-on="on"
                @click="downloadReceipts"
              >
                <i class="fas fa-download"></i>
              </v-btn>
            </template>
            <span>Download Selected Receipt(s)</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" md="12">
          <v-card>
            <v-data-table
              show-select
              v-model="selectedReceipts"
              :headers="receiptHeader"
              :items="receiptsList"
              :loading="receiptLoading"
              class="elevation-1"
              :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50],
              }"
              :server-items-length="totalItems"
              :options.sync="options"
              @update:options="fetchReceipts"
              @update:items-per-page="selectedReceipts = []"
              @update:page="selectedReceipts = []"
            >
              <template v-slot:[`header.name`]="{ header }">
                {{ header.text.toUpperCase() }}
              </template>
              <template v-slot:[`item.imgByte`]="{ item }">
                <v-img
                  v-if="isNotPdf(item.imageName)"
                  :src="`data:image/*;base64,${item.imgByte}`"
                  style="width: 50px; height: 50px"
                />
                <v-tooltip bottom v-else>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      class="ml-3"
                      size="30"
                      v-bind="attrs"
                      v-on="on"
                      color="#ff0000"
                      >fas fa-file-pdf</v-icon
                    >
                  </template>
                  PDF File
                </v-tooltip>
              </template>
              <template v-slot:[`item.receiptDate`]="{ item }">
                {{ item.receiptDate.substr(0, 10) }}
              </template>
              <template v-slot:[`item.cardTypeName`]="{ item }">
                {{ item.cardTypeName }}
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-menu
                  offset-y
                  max-width="250"
                  max-height="300"
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          icon
                          text
                          :disabled="item.notes === null"
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...menu }"
                        >
                          <v-icon color="primary">fa fa-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>View Notes</span>
                    </v-tooltip>
                  </template>
                  <div class="white pa-5" style="word-wrap: break-word">
                    <div class="font-weight-bold">NOTES:</div>
                    {{ item.notes }}
                  </div>
                </v-menu>
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      text
                      :disabled="item.imgByte === null"
                      @click="downloadReceipt(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="primary">fa fa-download</v-icon></v-btn
                    >
                  </template>
                  Download Receipt
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ReceiptService from "@/services/ReceiptService";

export default {
  data() {
    return {
      receiptHeader: [
        {
          text: "Receipt Image",
          value: "imgByte",
          width: 150,
          sortable: false,
        },
        { text: "Category", value: "categoryName", width: 120 },
        { text: "Event", value: "eventName", width: 100 },
        { text: "Amount", value: "amount", width: 120 },
        { text: "CC 4 Digit", value: "last4DigitofCC", width: 130 },
        { text: "Card Type", value: "cardTypeName", width: 130 },
        { text: "Receipt Date", value: "receiptDate", width: 150 },
        { text: "Added By", value: "addedBy", width: 140 },
        { text: "Actions", value: "action", width: 200, sortable: false },
      ],
      receiptsList: [],
      selectedReceipts: [],
      receiptLoading: false,
      totalItems: null,
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
      },
    };
  },
  computed: {
    ...mapState({
      userId: (state) => state.auth.user?.userId,
    }),
  },
  methods: {
    showNotes(index) {
      this.showNotesIndex = index;
    },
    async fetchReceipts() {
      this.isActive = false;
      this.receiptLoading = true;
      let sortBy = null;
      if (this.sortBy !== null) {
        switch (this.options.sortBy[0]) {
          case "categoryName":
            sortBy = "Category";
            break;
          case "eventName":
            sortBy = "Event";
            break;
          case "amount":
            sortBy = "Amount";
            break;
          case "last4DigitofCC":
            sortBy = "Last4DigitofCC";
            break;
          case "receiptDate":
            sortBy = "ReceiptDate";
            break;
          case "addedBy":
            sortBy = "AddedBy";
            break;
          case "cardTypeName":
            sortBy = "CardTypeName";
            break;
        }
      }
      await ReceiptService.fetchReceipts(
        this.options.page,
        this.options.itemsPerPage,
        sortBy || null,
        this.options.sortDesc[0] !== undefined
          ? this.options.sortDesc[0]
          : null,
        this.receiptDate,
        this.categoryId,
        this.eventId,
        this.amount,
        this.last4cc,
        this.cardId,
        this.userId
      )
        .then((res) => {
          this.receiptLoading = false;
          this.receiptsList = res.data.data.receiptList;
          this.totalItems = res.data.data.pagination.total;
        })
        .catch((error) => {
          this.receiptLoading = false;
          this.$store.dispatch("notifications/setNotification", {
            type: "error",
            text: error.response.data.errorMessage,
            status: error.response.status,
          });
        });
    },
    searchReceipt() {
      this.options = {
        page: 1,
        itemsPerPage: this.options.itemsPerPage,
        sortBy: [],
        sortDesc: [],
      };
      this.fetchReceipts();
    },
    async downloadReceipts() {
      for (const receipt of this.selectedReceipts) {
        await this.downloadReceipt(receipt);
      }
    },
    downloadReceipt(item) {
      return new Promise((resolve) => {
        setTimeout(() => {
          const linkSource = `data:image/*;base64,${item.imgByte}`;
          const downloadLink = document.createElement("a");
          downloadLink.href = linkSource;
          downloadLink.download = `${item.imageName}`;
          downloadLink.click();
          downloadLink.remove();
          return resolve();
        }, 500);
      });
    },
    isNotPdf(name) {
      return name.split(".")[1] !== "pdf";
    },
    resetfilter() {
      this.receiptDate = "";
      this.categoryId = "";
      this.eventId = "";
      this.last4cc = "";
      this.amount = "";
      this.cardId = "";
      this.options = {
        page: 1,
        itemsPerPage: this.options.itemsPerPage,
        sortBy: [],
        sortDesc: [],
      };
      this.fetchReceipts();
    },
  },
};
</script>
